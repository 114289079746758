import { Link as RouterLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Box,
  MenuItem,
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UsersIcon from '../../icons/Users';
import loadingImage from '../../media/ch-white-icon.png';
import CustomLogout from './CustomLogout';
import { useState } from 'react';
import { ExpandLess, ExpandMore, Search as SearchIcon, Close as CloseIcon, Menu as MenuIcon, Settings as SettingsIcon } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';
import QueueIcon from '@mui/icons-material/Queue';
import CurrencyDollar from '../../icons/CurrencyDollar';
import PurchasesSearchBar from './PurchasesSearchBar';
import useSettings from '../../hooks/useSettings';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChatIcon from '@mui/icons-material/Chat';

const NavLinks = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  flexGrow: 0,
  gap: 4,
  '& .MuiButton-root': {
    color: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.text.primary,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.text.primary,
  }
}));

const Logo = styled('div')({
  flexGrow: '1',
  cursor: 'pointer',
});

const StyledLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.text.primary,
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  '&:hover': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#ffffff' : theme.palette.text.primary}`,
    opacity: 0.85
  },
}));

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    '& .MuiButton-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    }
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    '& .MuiButton-root': {
      color: theme.palette.text.primary
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    }
  }),
  zIndex: theme.zIndex.drawer + 1,
  height: 64,
  display: 'flex',
  justifyContent: 'center'
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();
  const isAsync = user?.is_async;
  const roles = user?.roles;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  // State for dropdown menus
  const [customersDrop, setCustomersDrop] = useState(false);
  const [queuesDrop, setQueuesDrop] = useState(false);
  const [paymentDrop, setPaymentDrop] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // Boolean flags for menu states
  const openCustomersDrop = Boolean(customersDrop);
  const openQueuesDrop = Boolean(queuesDrop);
  const openPaymentDrop = Boolean(paymentDrop);
  // Menu handlers
  const handleCustomersDrop = (event) => {
    setCustomersDrop(event.currentTarget);
  };

  const handleQueuesDrop = (event) => {
    setQueuesDrop(event.currentTarget);
  };

  const handlePayment = (event) => {
    setPaymentDrop(event.currentTarget);
  };

  const handleCloseCustomers = () => {
    setCustomersDrop(null);
  };

  const handleCloseQueues = () => {
    setQueuesDrop(null);
  };

  const handleClosePayment = () => {
    setPaymentDrop(null);
  };

  const handleSearchToggle = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const { settings, saveSettings } = useSettings();
  const [settingsMenu, setSettingsMenu] = useState(null);
  const openSettings = Boolean(settingsMenu);

  const handleSettingsClick = (event) => {
    setSettingsMenu(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsMenu(null);
  };

  const handleThemeChange = () => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'LIGHT' ? 'DARK' : 'LIGHT'
    });
    handleSettingsClose();
  };

  // Menu items with role-based access
  const mainListsMenu = [
    {
      title: 'Purchases',
      icon: <ShoppingCartIcon fontSize="small" />,
      path: '/dashboard/purchases',
      roles: ['admin', 'ops', 'doctor', 'sales'],
    },
    {
      title: 'Customers',
      icon: <PeopleIcon fontSize="small" />,
      path: '/dashboard/customers',
      roles: ['admin', 'ops', 'doctor', 'sales', 'guide'],
    },
    {
      title: 'Sales',
      icon: <MonetizationOnIcon fontSize="small" />,
      path: '/dashboard/sales',
      roles: ['admin', 'ops', 'sales', 'guide'],
    },
    {
      title: 'Connect',
      icon: <ConnectWithoutContactIcon fontSize="small" />,
      path: '/dashboard/connect',
      roles: ['admin', 'ops', 'sales', 'guide'],
    },
    {
      title: 'Clinicians',
      icon: <LocalHospitalIcon fontSize="small" />,
      path: '/dashboard/clinicians',
      roles: ['admin', 'ops'],
    },
    {
      title: 'Test Kits',
      icon: <MedicalInformationIcon fontSize="small" />,
      path: '/dashboard/test-kits',
      roles: ['admin', 'ops'],
    },
    {
      title: 'NPS Surveys',
      icon: <FeedbackIcon fontSize="small" />,
      path: '/dashboard/nps-surveys',
      roles: ['admin', 'ops', 'guide', 'success_manager', 'sales'],
    },
    {
      title: 'Support Group Surveys',
      icon: <FeedbackIcon fontSize="small" />,
      path: '/dashboard/support-group-surveys',
      roles: ['admin', 'ops', 'guide', 'success_manager', 'sales'],
    },
    {
      title: 'Incoming Messages',
      icon: <ChatIcon fontSize="small" />,
      path: '/dashboard/twilio-replies',
      roles: ['admin', 'ops', 'doctor', 'guide', 'success_manager'],
    },
  ];

  const clinicalQueuesMenu = [
    {
      title: 'Naltrexone Refills',
      path: '/dashboard/customers/upcoming-nax-refills',
      roles: ['admin', 'ops', 'doctor'],
    },
    {
      title: 'Async Consultations',
      path: '/dashboard/async-consultations',
      roles: ['admin', 'ops', 'doctor'],
    },
    {
      title: 'Insurance Consults',
      path: '/dashboard/customers/upcoming-insurance-consults',
      roles: ['admin', 'ops', 'doctor'],
    },
    {
      title: 'Doctor Requests',
      path: '/dashboard/doctor-requests',
      roles: ['admin', 'ops', 'doctor'],
    },
  ];

  const paymentsMenu = [
    {
      title: 'Plans',
      path: '/dashboard/nmi/plans',
      roles: ['admin'],
    },
    {
      title: 'Orders',
      path: '/dashboard/orders',
      roles: ['admin', 'ops'],
    },
    {
      title: 'Collections',
      path: '/dashboard/collections',
      roles: ['admin', 'ops'],
    },
  ];

  // Filter function for menu items
  const hasAccess = (item) => {
    if (item.requiresAsync && !isAsync) return false;
    return !item.roles || item.roles.some((role) => roles?.includes(role));
  };

  // Check if any menu items are accessible
  const hasAccessibleItems = (menu) => menu.some(hasAccess);

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          height: 64,
          left: 0,
          px: 2,
          width: '100%'
        }}
      >
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {!isMobile || !isSearchOpen ? (
            <>
              <RouterLink
                to="/"
                className={Logo}
              >
                <img
                  src={loadingImage}
                  alt="chooselife"
                  height="40"
                  width="40"
                  display="inline"
                />
              </RouterLink>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!isMobile && <PurchasesSearchBar />}
              </Box>
            </>
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <PurchasesSearchBar />
            </Box>
          )}
        </Box>

        {isMobile ? (
          <>
            {isSearchOpen ? (
              <IconButton 
                onClick={handleSearchToggle}
                sx={{ 
                  color: theme => theme.palette.mode === 'light' 
                    ? theme.palette.primary.contrastText 
                    : theme.palette.text.primary 
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton 
                onClick={handleSearchToggle}
                sx={{ 
                  color: theme => theme.palette.mode === 'light' 
                    ? theme.palette.primary.contrastText 
                    : theme.palette.text.primary 
                }}
              >
                <SearchIcon />
              </IconButton>
            )}
            {!isSearchOpen && (
              <IconButton 
                onClick={onSidebarMobileOpen}
                sx={{ 
                  color: theme => theme.palette.mode === 'light' 
                    ? theme.palette.primary.contrastText 
                    : theme.palette.text.primary 
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <NavLinks>
              <RouterLink
                to="/dashboard/home"
              >
                <Button
                  component={StyledLink}
                  to="/dashboard/home"
                >
                  HOME
                </Button>
              </RouterLink>

              <Button
                id="lists-button"
                aria-controls={openCustomersDrop ? 'lists-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCustomersDrop ? 'true' : undefined}
                component={StyledLink}
                onClick={handleCustomersDrop}
              >
                MAIN LISTS
                {openCustomersDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="lists-menu"
                anchorEl={customersDrop}
                open={openCustomersDrop}
                onClose={handleCloseCustomers}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {mainListsMenu
                  .filter(hasAccess)
                  .map((item) => (
                    <MenuItem
                      key={item.path}
                      component={Link}
                      to={item.path}
                      onClick={handleCloseCustomers}
                    >
                      <ListItemIcon>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
              </Menu>
              {hasAccessibleItems(clinicalQueuesMenu) && (
                <>
                  <Button
                    id="queues-button"
                    aria-controls={openQueuesDrop ? 'queues-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openQueuesDrop ? 'true' : undefined}
                    component={StyledLink}
                    onClick={handleQueuesDrop}
                  >
                    QUEUES
                    {openQueuesDrop ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                  <Menu
                    id="queues-menu"
                    anchorEl={queuesDrop}
                    open={openQueuesDrop}
                    onClose={handleCloseQueues}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {clinicalQueuesMenu
                      .filter(hasAccess)
                      .map((item) => (
                        <MenuItem
                          key={item.path}
                          component={Link}
                          to={item.path}
                          onClick={handleCloseQueues}
                        >
                          <ListItemIcon>
                            <QueueIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
              {hasAccessibleItems(paymentsMenu) && (
                <>
                  <Button
                    id="payments-button"
                    aria-controls={openPaymentDrop ? 'payments-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openPaymentDrop ? 'true' : undefined}
                    component={StyledLink}
                    onClick={handlePayment}
                  >
                    PAYMENTS
                    {openPaymentDrop ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                  <Menu
                    id="payments-menu"
                    anchorEl={paymentDrop}
                    open={openPaymentDrop}
                    onClose={handleClosePayment}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {paymentsMenu
                      .filter(hasAccess)
                      .map((item) => (
                        <MenuItem
                          key={item.path}
                          component={Link}
                          to={item.path}
                          onClick={handleClosePayment}
                        >
                          <ListItemIcon>
                            <CurrencyDollar fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
              <Button
                id="settings-button"
                aria-controls={openSettings ? 'settings-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSettings ? 'true' : undefined}
                component={StyledLink}
                onClick={handleSettingsClick}
              >
                <SettingsIcon sx={{ mr: 1 }} />
                Settings
              </Button>
              <Menu
                id="settings-menu"
                anchorEl={settingsMenu}
                open={openSettings}
                onClose={handleSettingsClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleThemeChange}>
                  <ListItemIcon>
                    {settings.theme === 'LIGHT' ? <DarkModeIcon /> : <LightModeIcon />}
                  </ListItemIcon>
                  <ListItemText primary={`Switch to ${settings.theme === 'LIGHT' ? 'Dark' : 'Light'} Mode`} />
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to="/dashboard/account"
                  onClick={handleSettingsClose}
                >
                  Manage Account
                </MenuItem>
              </Menu>
              <Box sx={{ ml: 2 }}>
                <CustomLogout />
              </Box>
            </NavLinks>
          </>
        )}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
