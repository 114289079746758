import axios from 'axios';

const defaultAxiosConfig = (accessToken) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: accessToken
  }
});

class AdminApi {
  async getClinicians() {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/admin/clinicians`,
      ...defaultAxiosConfig(accessToken)
    });

    return response.data;
  }

  async getSuccessManagers() {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/admin/success-managers`,
      ...defaultAxiosConfig(accessToken)
    });

    return response.data;
  }

  async updateClinicianStates(clinicianId, states) {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_BACKEND_URL}/admin/clinicians/${clinicianId}`,
      data: states,
      ...defaultAxiosConfig(accessToken)
    });

    return response.data;
  }

  async changePassword(data) {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/admin/change-admin-user-password`,
      data,
      ...defaultAxiosConfig(accessToken)
    });

    return response.data;
  }
}

export const adminApi = new AdminApi(); 