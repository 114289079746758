import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from '@mui/material';
import moment from 'moment';

const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'optimal':
      return 'success';
    case 'elevated':
      return 'warning';
    case 'very highly elevated':
      return 'error';
    default:
      return 'default';
  }
};

const TestResultsModal = ({ open, onClose, testKit }) => {
  if (!testKit) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6">
          Test Results - {testKit.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Collected: {moment(testKit.collection_date).format('MM/DD/YYYY')} | 
          Results Received: {moment(testKit.results_collection_date).format('MM/DD/YYYY')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Marker</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Optimal Range</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testKit.results?.map((result) => (
                <TableRow key={result.markerId}>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {result.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {result.value} {result.unit}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {result.optimalRange} {result.unit}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={result.status}
                      color={getStatusColor(result.status)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

TestResultsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  testKit: PropTypes.shape({
    type: PropTypes.string,
    collection_date: PropTypes.string,
    results_collection_date: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.string,
      status: PropTypes.string,
      markerId: PropTypes.string,
      optimalRange: PropTypes.string,
    })),
  }),
};

export default TestResultsModal; 