import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  Container,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  Grid,
  Chip,
  Select,
  MenuItem,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SearchIcon from '../../icons/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { customerApi } from '../../api/customerApi';
import { debounce } from 'lodash';
import TestKitsListColumns from '../../utils/columns/TestKitsListColumns';
import { toast } from 'react-hot-toast';
import TestResultsModal from '../../components/dashboard/test-kits/TestResultsModal';

const TEST_KIT_STATUS = ['registered', 'unregistered', 'completed'];

const TestKitsList = () => {
  const [testKits, setTestKits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem('testKitsListFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      status: '',
      purchaseId: '',
    };
  });
  const [selectedTestKit, setSelectedTestKit] = useState(null);
  const [resultsModalOpen, setResultsModalOpen] = useState(false);

  const handleViewResults = useCallback((testKit) => {
    setSelectedTestKit(testKit);
    setResultsModalOpen(true);
  }, []);

  const handleCloseResults = useCallback(() => {
    setResultsModalOpen(false);
    setSelectedTestKit(null);
  }, []);

  const columns = TestKitsListColumns(handleViewResults);

  const getTestKits = useCallback(async (searchValue) => {
    setLoading(true);
    try {
      const params = {
        status: filters.status || undefined,
        purchaseId: filters.purchaseId || undefined,
        searchValue: searchValue?.toLowerCase(),
      };

      const data = await customerApi.getTestKits(params);
      setTestKits(data);
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch test kits');
    }
    setLoading(false);
  }, [filters]);

  const debouncedGetTestKits = useCallback(
    debounce((newQuery) => {
      getTestKits(newQuery);
    }, 300),
    [getTestKits]
  );

  useEffect(() => {
    getTestKits(searchQuery);
  }, [getTestKits, filters]);

  useEffect(() => {
    sessionStorage.setItem('testKitsListFilters', JSON.stringify(filters));
    sessionStorage.setItem('testKitsListQuery', searchQuery);
  }, [filters, searchQuery]);

  const handleQueryChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    debouncedGetTestKits(newQuery);
  };

  const handleOpenFilters = () => {
    setOpenFilters(true);
  };

  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleApplyFilters = () => {
    getTestKits(searchQuery);
    handleCloseFilters();
  };

  const handleRemoveFilter = (filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: ''
    }));
    getTestKits(searchQuery);
  };

  const handleRemoveAllFilters = () => {
    const defaultFilters = {
      status: '',
      purchaseId: '',
    };
    setFilters(defaultFilters);
    setSearchQuery('');
    sessionStorage.setItem('testKitsListFilters', JSON.stringify(defaultFilters));
    sessionStorage.setItem('testKitsListQuery', '');
    getTestKits('');
  };

  const renderActiveFilters = () => {
    const activeFilters = Object.entries(filters).filter(([key, value]) => value !== '');

    if (activeFilters.length === 0 && !searchQuery) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        {activeFilters.map(([key, value]) => (
          <Chip
            key={key}
            label={`${key}: ${value}`}
            onDelete={() => handleRemoveFilter(key)}
            color="primary"
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {searchQuery && (
          <Chip
            label={`Search: ${searchQuery}`}
            onDelete={() => setSearchQuery('')}
            color="primary"
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
          />
        )}
        <Chip
          label="Clear All Filters"
          onDelete={handleRemoveAllFilters}
          color="secondary"
          variant="outlined"
          sx={{ mr: 1, mb: 1 }}
        />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Test Kits | Choose Life</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder="Search test kits..."
                variant="outlined"
                value={searchQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}
                onClick={handleOpenFilters}
                size="small"
              >
                Filters
              </Button>
            </Grid>
          </Grid>
          {renderActiveFilters()}
          <Box sx={{ mt: 3 }}>
            <Card>
              <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                <DataGridPro
                  rows={testKits}
                  columns={columns}
                  loading={loading}
                  pageSizeOptions={[25, 50, 100]}
                  disableSelectionOnClick
                  pagination
                />
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
      <Dialog open={openFilters} onClose={handleCloseFilters} maxWidth="md" fullWidth>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Status</Typography>
                <Select
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                >
                  <MenuItem value="">All Statuses</MenuItem>
                  {TEST_KIT_STATUS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Purchase ID</Typography>
                <TextField
                  value={filters.purchaseId}
                  onChange={(e) => handleFilterChange('purchaseId', e.target.value)}
                  placeholder="Enter Purchase ID"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilters} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyFilters} color="primary" variant="contained">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
      <TestResultsModal
        open={resultsModalOpen}
        onClose={handleCloseResults}
        testKit={selectedTestKit}
      />
    </>
  );
};

export default TestKitsList; 