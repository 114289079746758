import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { adminApi } from '../../api/adminApi';
import { toast } from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required')
});

const Account = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await adminApi.changePassword({
        email: user.email,
        password: values.password
      });
      
      toast.success('Password changed successfully. You will be logged out in 3 seconds...');
      
      // Delay logout to show the success message
      setTimeout(async () => {
        try {
          await logout();
          navigate('/');
        } catch (err) {
          console.error('Failed to logout:', err);
          toast.error('Password changed but logout failed. Please logout manually.');
        }
      }, 3000);
    } catch (error) {
      console.error('Failed to change password:', error);
      toast.error('Failed to change password');
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Account Management | Choose Life</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" sx={{ mb: 3 }}>
            Account Management
          </Typography>
          <Card>
            <CardContent>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: ''
                }}
                validationSchema={passwordSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, handleChange, values }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={user.email}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Confirm New Password"
                          name="confirmPassword"
                          type="password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          sx={{ mt: 2 }}
                          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                        >
                          {isSubmitting ? 'Changing Password...' : 'Change Password'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Account;
