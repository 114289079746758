import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Box, Button } from '@mui/material';
import Label from '../../components/Label';
import moment from 'moment';
import LabResultsIcon from '@mui/icons-material/Science';

const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
        case 'registered':
            return 'info';
        case 'unregistered':
            return 'warning';
        case 'collected':
            return 'info';
        case 'completed':
            return 'success';
        case 'results_received':
            return 'success';
        default:
            return 'default';
    }
};

const TestKitsListColumns = (handleViewResults) => [
    {
        field: 'id',
        headerName: 'Test Kit ID',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.id || 'N/A',
    },
    {
        field: 'patient',
        headerName: 'Patient',
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => {
            const { row } = params;
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Link
                        component={RouterLink}
                        to={`/dashboard/customers/${row.user_id}`}
                        color="primary"
                        sx={{ fontWeight: 500 }}
                    >
                        {row.name || 'N/A'}
                    </Link>
                    <Typography variant="body2" color="textSecondary">
                        {row.email || 'No email'}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => {
            const type = params.row.type || '';
            return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
    },
    {
        field: 'todo_key',
        headerName: 'Test Number',
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => {
            const todoKey = params.row.todo_key || '';
            const match = todoKey.match(/_(\d+)$/);
            return match ? `Test ${match[1]}` : 'Initial Test';
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 120,
        renderCell: (params) => (
            <Label color={getStatusColor(params.row.status)}>
                {params.row.status ? params.row.status.replace(/_/g, ' ').toUpperCase() : 'Unknown'}
            </Label>
        ),
    },
    {
        field: 'dates',
        headerName: 'Important Dates',
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => {
            const { registration_date, collection_date, results_collection_date } = params.row;
            const dates = [];

            if (registration_date) dates.push(`Registered: ${moment(registration_date).format('MM/DD/YY')}`);
            if (collection_date) dates.push(`Collected: ${moment(collection_date).format('MM/DD/YY')}`);
            if (results_collection_date) dates.push(`Results: ${moment(results_collection_date).format('MM/DD/YY')}`);

            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', py: 1.5, width: '100%' }}>
                    {dates.length > 0 ? dates.map((date, index) => (
                        <Typography 
                            key={index} 
                            variant="caption" 
                            sx={{ 
                                mb: index < dates.length - 1 ? 0.5 : 0,
                                color: 'text.secondary'
                            }}
                        >
                            {date}
                        </Typography>
                    )) : (
                        <Typography variant="caption" color="text.secondary">
                            No dates recorded
                        </Typography>
                    )}
                </Box>
            );
        },
    },
    {
        field: 'barcode',
        headerName: 'Barcode',
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.barcode_id || 'Not Registered',
    },
    {
        field: 'created_at',
        headerName: 'Created',
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.created_at,
        renderCell: (params) => (
            <Typography variant="body2">
                {params.value ? moment(params.value).format('MM/DD/YY') : 'N/A'}
            </Typography>
        ),
    },
    {
        field: 'updated_at',
        headerName: 'Updated',
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.updated_at,
        renderCell: (params) => (
            <Typography variant="body2">
                {params.value ? moment(params.value).format('MM/DD/YY') : 'N/A'}
            </Typography>
        ),
    },
    {
        field: 'results',
        headerName: 'Results',
        width: 120,
        renderCell: (params) => {
            const hasResults = params.row.results && params.row.results.length > 0;

            if (!hasResults) {
                return '-';
            }

            return (
                <Button
                    startIcon={<LabResultsIcon />}
                    size="small"
                    onClick={() => handleViewResults(params.row)}
                    variant="outlined"
                >
                    View
                </Button>
            );
        },
    },
];

export default TestKitsListColumns; 